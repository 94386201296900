import React from 'react'
import Landing from './components/Landing'
import Navbar from "./components/Navbar";
import About from "./components/About";
import Portfolio from "./components/Products";
import Contact from "./components/Footer";
import Contact1 from './components/Contact';


import { Route, Routes } from "react-router-dom";

const App = () => {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Landing/>}/>
        <Route path="/landing" element={<Landing/>}/>
        <Route path='/about' element={<About />} />
        <Route path='/products' element={<Portfolio />} ></Route>
        <Route path='/contact' element={<Contact1 />} ></Route>
      </Routes>
      <Contact />
    </>
  )
}

export default App