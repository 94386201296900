import * as React from 'react';
import '../Css/Navbar.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone,  } from '@fortawesome/fontawesome-free-solid';
import { Link } from "react-router-dom";



const Navbar = () => {
    return (
        <>
            <div className="container-fluid shadow bg-light text-dark" style={{ position: "sticky", top: "0", }}>
                <div className=" d-flex container justify-content-between p-3 text-center header " >
                    <div className="">
                        {/*<img src={LogoImage} alt="" class="img-fluid logo "></img>*/}<h2>AK Solar</h2>
                    </div>
                
                    <div className="p-2">
                        <FontAwesomeIcon icon={faPhone} className="mx-2" style={{ color: "#26d99b" }}></FontAwesomeIcon>
                        <a href="/landing" style={{ textDecoration: "none" }}>+91-8384833561</a><br></br>
                        Call us For Enquiry

                    </div>
                </div>
                <nav class="navbar navbar-expand-lg navbar-light bg-light">
                    <div class="container">
                        
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                               
                                    <li class="nav-item">
                                        <Link class="nav-link active mx-3 "  to="/">Home</Link>
                                    </li>
                                    <li class="nav-item">
                                    <Link class="nav-link mx-3 " to="/about">About us</Link>
                                    </li>
                                    <li class="nav-item">
                                        <Link class="nav-link mx-3 " to="/gallery">Gallery</Link>
                                    </li>
                                    <li class="nav-item">
                                    <Link class="nav-link mx-3 " to="/products">Products</Link>
                                    </li>
                                    <li class="nav-item dropdown">
                                        <a class="nav-link  mx-3 dropdown-toggle" href="/products" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Category
                                        </a>
                                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <li><a class="dropdown-item" href="/products">All Products</a></li>
                                            <li><a class="dropdown-item" href="/products">On Grid Solar System</a></li>

                                            <li><a class="dropdown-item" href="/products">Off Grid Solar System</a></li>
                                        </ul>
                                    </li>
                                    <li class="nav-item">
                                        <Link class="nav-link mx-3 " to="/contact">Contact us</Link>
                                    </li>
                               
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>

        </>
    );
};
export default Navbar;
