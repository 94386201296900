import React from 'react'
import "../Css/landing.css";
import img1 from "../images/img1.jfif";
import img2 from "../images/carousel-2.jpg";
import img3 from "../images/carousel-3.jpg";
import img4 from "../images/carousel-4.jpg";
import home from "../images/home_01.jpg";
import certificate from "../images/aksolar-certificate.jpeg";
import amil from "../images/amil.jfif";
import adnan from "../images/mohammad adnan khan.jfif";
import babu from "../images/mohn babu.jfif";
import manish from "../images/MANISH.jpg"
import imran from "../images/imran ahmad.jfif";
import video from "../video.mp4";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressBook, faHandsHelping, } from '@fortawesome/fontawesome-free-solid';

const Landing = () => {
    return (
        <>

            <div style={{ position: "relative", zIndex: "-2" }}>
                <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    </div>
                    <div class="carousel-inner p-3 rounded">
                        <div class="carousel-item active">
                            <img src={img1} class="img-fluid carousel-image-width" alt="..." />
                        </div>
                        <div class="carousel-item">
                            <img src={img2} class="img-fluid carousel-image-width" alt="..." />
                        </div>
                        <div class="carousel-item">
                            <img src={img3} class="img-fluid carousel-image-width" alt="..." />
                        </div>
                        <div class="carousel-item">
                            <img src={img4} class="img-fluid carousel-image-width" alt="..." />
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
                {/*cards*/}
                <div className="container my-4">
                    <div className="row p-3">
                        <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                            <div class="card card_section2 card-color animate__animated abc " style={{ borderRadius: "10px" }}>
                                <div class="card-body">
                                    <p>Welcome Message</p>
                                    <hr style={{ border: "1px solid gray", width: " 7rem", marginTop: " -10px;" }} />
                                    <p style={{ fontSize: "0.8rem", marginTop: "2px" }}>AK Solar welcomes you.</p>
                                </div>
                                <div style={{ backgroundColor: "#22BFA0", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}><a href="https://brajsolar.com/index.php/Welcome/about_us" style={{ color: "white" }}>Learn More</a></div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                            <div class="card card_section2 hovercolor2 animate__animated abc " style={{ borderRadius: "10px" }}>
                                <div class="card-body">
                                    <p>Our Mission</p>
                                    <hr style={{ border: "1px solid gray", width: " 7rem", marginTop: " -10px;" }} />
                                    <p style={{ fontSize: "0.8rem", marginTop: "2px" }}>Drive strategy and operations through cutting edge technology, deploy innovative and collaborat...</p>
                                </div>
                                <div style={{ backgroundColor: "#22BFA0", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}><a href="https://brajsolar.com/index.php/Welcome/about_us" style={{ color: "white" }}>Learn More</a></div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                            <div class="card card_section2 hovercolor2 animate__animated abc " style={{ borderRadius: "10px" }}>
                                <div class="card-body">
                                    <p>Our Vision</p>
                                    <hr style={{ border: "1px solid gray", width: " 7rem", marginTop: " -10px;" }} />
                                    <p style={{ fontSize: "0.8rem", marginTop: "2px" }}>To become one of the leading clean and renewable energy companies </p>
                                </div>
                                <div style={{ backgroundColor: "#22BFA0", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}><a href="https://brajsolar.com/index.php/Welcome/about_us" style={{ color: "white" }}>Learn More</a></div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                            <div class="card card_section2 hovercolor2 animate__animated abc " style={{ borderRadius: "10px" }}>
                                <div class="card-body" >
                                    <p>What We Are</p>
                                    <hr style={{ border: "1px solid gray", width: " 7rem", marginTop: " -10px;" }} />
                                    <p style={{ fontSize: "0.8rem", marginTop: "2px" }}>We will ensure that our approach to business always factors a holistic sustainability vision..</p>
                                </div>
                                <div style={{ backgroundColor: "#22BFA0", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}><a href="https://brajsolar.com/index.php/Welcome/about_us" style={{ color: "white" }}>Learn More</a></div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*about us */}
                <div className="container p-3">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6" data-aos="fade-right">
                            <div className=" ">
                                <h2>About us</h2>
                                <hr className="w-25" style={{ height: "4px" }} />
                            </div>
                            <h3>We are Builders of Future</h3>
                            <p>Solar has a powerful impact. Generate your own clean energy,
                                reduce your monthly electricity bills, protect yourself from rate hikes
                                and use those savings to empower your life. It’s a substantial payoff,
                                and you get the feel-good bonus for doing the environmentally right
                                thing.In addition to being a local solar solution, we are also a licensed</p>
                            <div className="btn btn-primary">Read More</div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6" data-aos="fade-left">
                            <img src={home} className="img-fluid" alt="/"></img>
                        </div>
                    </div>
                </div>
                {/*certificate*/}
                <div className="container my-3">
                    <div className="row p-3">
                        <div className="col-12 col-sm-12 col-md-5 col-lg-5" data-aos="fade-right">
                            <img src={certificate}  alt="/" className="img-fluid"></img>
                        </div>
                        <div className="col-12 col-sm-12 col-md-7 col-lg-7 py-3" data-aos="fade-left">
                            <p>
                                Authorized distributor of Solar Panels by Loom Solar India's Premium Solar Brand.s
                            </p>
                            <button className="btn btn-primary">About us</button>
                        </div>
                    </div>
                </div>
                {/*published artical*/}
                <div className="container my-3">
                    <div className="row p-3">
                        <div className="col-12 col-sm-12 col-md-7 col-lg-7 py-3">
                            <h4 className='py-3'>WHAT WE PROVIDE IN AK SOLAR</h4>
                            <p>Solar has a powerful impact. Generate your own clean energy,
                                reduce your monthly electricity bills, protect yourself from rate hikes
                                and use those savings to empower your life. It’s a substantial payoff,
                                and you get the feel-good bonus for doing the environmentally right
                                thing.In addition to being a local solar solution, we are also a licensed</p>
                            <button className="btn btn-primary">Read More</button>
                        </div>
                        <div className="col-12 col-sm-12 col-md-5 col-lg-5">
                            <iframe src={video} width="100%" height="100%" title='your video' frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style={{ border: "1px solid black" }}></iframe>
                        </div>
                    </div>
                </div>
                {/*meet the team */}
                <div className="container p-3">
                    <div className="row w-100 mx-auto">
                        <div className=" text-center mb-4">
                            <h2>Meet The Team</h2>
                            <hr className="w-25 mx-auto" style={{ height: "4px" }} />
                        </div>
                        <div className="col-12 col-md-3 col-lg-2 text-center mx-3">
                            <div className="imageborder"><img src={adnan}   alt="/" className="img-fluid border" /></div>
                            <div>
                                <h5>MOHAMMAD ADNAN KHAN </h5>
                                <i>(CEO)</i><br></br>
                               
                            </div>
                        </div>
                        <div className="col-12 col-md-3 col-lg-2 text-center mx-3">
                            <div className="imageborder"><img src={amil}  alt="/" className="img-fluid border" /></div>
                            <div>
                                <h5>MOHAMMAD AMIL </h5>
                                <i>SALES MANAGER</i><br></br>
                               
                            </div>
                        </div>
                        <div className="col-12 col-md-3 col-lg-2 text-center mx-3">
                            <div className="imageborder"><img src={manish}   alt="/" className="img-fluid border" /></div>
                            <div>
                                <h5>MANISH SINGH </h5>
                                <i>(BUSINESS ASSOCIATE)</i><br></br>
                                
                            </div>
                        </div>
                        <div className="col-12 col-md-3 col-lg-2 text-center mx-3">
                            <div className="imageborder"><img src={imran}  alt="/" className="img-fluid border" /></div>
                            <div>
                                <h5>IMRAN AHMAD </h5>
                                <i>(BUSINESS ASSOCIATE)</i><br></br>
                               
                            </div>
                        </div>
                       
                        <div className="col-12 col-md-3 col-lg-2 text-center mx-3">
                            <div className="imageborder"><img src={babu}  alt="/" className="img-fluid border" /></div>
                            <div>
                                <h5>MOHD SAHU </h5>
                                <i>(OPERATIONS MANAGER)</i><br></br>
                               
                            </div>
                        </div>
                    </div>
                </div>
                {/*section*/}
                <div class="container-fluid" style={{ marginTop: "20px" }}>
                    <div class="row d-flex justify-content-center" style={{ backgroundImage: "url('https://brajsolar.com/assets/images/cover.jpg')", width: "-webkit-fill-available", minHeight: "17rem" }}>
                        <div class="col-md-12">
                            <h2 style={{ textAlign: "center", marginTop: "30px", color: "white",  }}>Our Brands</h2>
                        </div>

                        <div class="col-md-12" style={{ display: "inline-flex" }}>
                            <marquee direction="right">
                                <img class="clientsimage animate__animated" src="https://brajsolar.com/assets/images/loomsolar.jpg"  alt="/" />
                                <img class="clientsimage animate__animated"  alt="/" src="https://brajsolar.com/assets/images/luminous-logo.png" />
                                <img class="clientsimage animate__animated"  alt="/" src="https://brajsolar.com/assets/images/microtek-logo.png" />
                                <img class="clientsimage animate__animated"  alt="/" src="https://brajsolar.com/assets/images/okayalogo.png" />
                                <img class="clientsimage animate__animated"  alt="/" src="https://brajsolar.com/assets/images/smarten.png" />
                                <img class="clientsimage animate__animated"  alt="/" src="https://brajsolar.com/assets/images/UTL-Solar-Final-Logo.png" />

                            </marquee>
                        </div>
                    </div>
                </div>
                {/*contact us*/}
                <div className="container mx-auto text-center">
                    <div class="row d-flex justify-content-center" style={{ marginTop: " 20px", marginBottom: "70px" }}>
                        <div><iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14102.806461286498!2d78.0846775!3d27.9111286!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1ccdf2ec5eb16e7a!2sVenus%20Tower!5e0!3m2!1sen!2sin!4v1645177079361!5m2!1sen!2sin" title='video' frameborder="0" style={{ border: "0", position: "relative", width: "100%", height: "100%" }} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe></div>
                    </div>
                    <div className="row ">
                        <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                            <FontAwesomeIcon icon={faAddressBook}></FontAwesomeIcon><br />
                            24*7 Support
                        </div>
                        <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                            <FontAwesomeIcon icon={faAddressBook}></FontAwesomeIcon><br />
                            Authorise Distributor
                        </div>
                        <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                            <FontAwesomeIcon icon={faHandsHelping}></FontAwesomeIcon><br />
                            Delivery
                            Within 3 to 7 days
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Landing