import React from 'react'

const Contact = () => {
    return (
        <div>
            <div className="container-fluid mt-4 bg-light">
                <div className="container">
                    <div className="row p-4">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                            <h5>Contact us</h5>
                            <h6>Address:</h6>
                            <small>
                               Civil Lines Aligarh,202001(U.P.),India
                            </small><br />
                            <h6>Email:</h6>
                            <small>
                                adnan_alig@yahoo.com
                            </small>
                            <br />
                            <h6>Phones: +91-8384833561</h6>
                            <div className="d-flex my-4">
                                <a href='https://web.whatsapp.com/' className='text-dark'> <i class="fa fa-whatsapp fa-2x mr-2" ></i></a>
                                <a href='https://www.facebook.com/' className='text-dark'><i class="fa fa-facebook fa-2x mx-2 "></i></a>
                                <a href="https://twitter.com/i/flow/login?input_flow_data=%7B%22requested_variant%22%3A%22eyJsYW5nIjoiZW4ifQ%3D%3D%22%7D" className='text-dark'><i class="fa fa-twitter fa-2x mx-2"></i></a>
                                <a href="#" className='text-dark'><i class="fa fa-google fa-2x mx-2"></i></a>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6">

                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Name:</label>
                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />

                            </div>
                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Email:</label>
                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />

                            </div>
                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Message:</label>
                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                            </div>                            <button type="submit" class="btn btn-primary">Submit</button>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Contact