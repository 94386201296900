import { faEnvelope,  faHome, faPhone } from '@fortawesome/fontawesome-free-solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const Contact1 = () => {
    return (
        <div className='container my-4' style={{ position: "relative", zIndex: "-2" }}>
            <h2 className=''>Contact Information</h2>
            <hr className="w-25 mx-0 mb-2" style={{height:"2px"}} />
            <p>We love to hear from you on our customer service, products, website or any topics you want to share with us. Your comments and suggestions will be appreciated. Please complete the form below.</p>
            <p><FontAwesomeIcon icon={faHome} className="mx-2"></FontAwesomeIcon> Office : Civil Lines Aligarh,202001(U.P.),India</p>
            <p><FontAwesomeIcon icon={faEnvelope} className="mx-2"></FontAwesomeIcon>Email:  adnan_alig@yahoo.com</p>
            <p><FontAwesomeIcon icon={faPhone} className="mx-2"></FontAwesomeIcon>Phone no: +91-8384833561</p>

        </div>
    )
}

export default Contact1