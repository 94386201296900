import React from 'react'
import about from "../images/about.jpg";
import "../Css/About.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDotCircle, faQuoteLeft, faQuoteRight } from '@fortawesome/fontawesome-free-solid';
import Contact from './Footer';
import Navbar from './Navbar';

const About = () => {
    return (
        <div>
        
            <div className="container-fluid" style={{ position: "relative", zIndex: "-2" }}>
                <div className="container">
                    <img src={about} className="img-fluid aboutImage"></img>
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-6 pt-5">
                            <p> <FontAwesomeIcon icon={faQuoteLeft} className="mx-3 fa-2x"></FontAwesomeIcon>The term solar panel is used colloquially for a photo-voltaic (PV) module. A PV module is an assembly of photo-voltaic cells mounted in a framework for installation. Photo-voltaic cells use sunlight as a source of energy and generate direct current electricity. A collection of PV modules is called a PV Panel, and a system of Panels is an Array. Arrays of a photovoltaic system supply solar electricity to electrical equipment. The most common application of solar energy collection outside agriculture is solar water  systems.<FontAwesomeIcon icon={faQuoteRight} className="mx-3 fa-2x"></FontAwesomeIcon></p>

                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <img src={about} className="img-fluid aboutImage"></img>
                        </div>
                    </div>
                    <div className="my-5 text-center">
                        <h4>Our Mission</h4>
                        <hr style={{ height: "3px", color: "red", width: "20%", margin: "auto" }}></hr>
                        <p className="mt-3"> <FontAwesomeIcon icon={faQuoteLeft} className="mx-3 fa-x"></FontAwesomeIcon>To become one of the leading clean and renewable energy companies by providing sustainable energy solutions through deployment of technology and innovation in a socially responsible manner.<FontAwesomeIcon icon={faQuoteRight} className="mx-3 fa-x"></FontAwesomeIcon></p>
                    </div>
                    <div className="my-5 text-center">
                        <h4>Our Vission</h4>
                        <hr style={{ height: "3px", color: "red", width: "20%", margin: "auto" }}></hr>
                        <p className="mt-3"> <FontAwesomeIcon icon={faQuoteLeft} className="mx-3 fa-x"></FontAwesomeIcon>Drive strategy and operations through cutting edge technology, deploy innovative and collaborative project structures and always ensure inclusion and growth by for our stakeholders and communities alike.<FontAwesomeIcon icon={faQuoteRight} className="mx-3 fa-x"></FontAwesomeIcon></p>
                    </div>
                    <div className="my-5 text-center">
                        <h4>Our Team</h4>
                        <hr style={{ height: "3px", color: "red", width: "20%", margin: "auto" }}></hr>
                        <p className="mt-3"> <FontAwesomeIcon icon={faQuoteLeft} className="mx-3 fa-x"></FontAwesomeIcon>We will ensure that our approach to business always factors a holistic sustainability vision – which includes but is not limited to.
                            <br></br>
                            <div><FontAwesomeIcon icon={faDotCircle} className="mx-2" style={{ fontSize: "10px" }}></FontAwesomeIcon><small> Bettering the lives of the communities we operate in</small></div>
                            <div><FontAwesomeIcon icon={faDotCircle} className="mx-2" style={{ fontSize: "10px" }}></FontAwesomeIcon><small>Being socially responsiblein</small></div>
                            <div><FontAwesomeIcon icon={faDotCircle} className="mx-2" style={{ fontSize: "10px" }}></FontAwesomeIcon><small> Being transparent </small></div>
                            <div><FontAwesomeIcon icon={faDotCircle} className="mx-2" style={{ fontSize: "10px" }}></FontAwesomeIcon><small> Being environmentally responsible in</small></div>
                            <FontAwesomeIcon icon={faQuoteRight} className="mx-3 fa-x"></FontAwesomeIcon></p>
                    </div>
                </div>
               
            </div>
        </div>
    )
}

export default About